@import 'styles/variables/mqueries.scss';

section#content {
    position: relative;
}

section#content.magnify {
    & section#main{
        & #main-noticias{
            & .list-container{
                & .list{
                    display:flex;
                    width: 40% ;
                    flex-direction: column;
                    & article{
                        width: 100%;
                    }
                }
            }
        }
    }
    & section#sidebar{
        background:white!important;
        width: 65vw;
    }
}